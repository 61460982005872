const fullScreenModalMixin = {
    computed: {
        modalWindowFullscreen() {
            return this.$store.state.modals.modals.modalFullscreen;
        }
    },
    methods: {
        modalWindowFullscreenToggle() {
            this.$emit('before-fullscreen');
            this.$nextTick(() => {
                this.$store.commit('modals/toggleModalFullscreen');
            });
        }
    }
};

export default fullScreenModalMixin;
