export function disableBodyScroll(isHide) {
    const body = document.body;
    if(isHide && body.offsetHeight === body.scrollHeight) return;
    if(isHide) {
        body.style.paddingRight = '6px';
        body.style.overflow = 'hidden';
    } else {
        body.style.paddingRight = '';
        body.style.overflow = '';
    }
}
