var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:[_vm.$style['modal-wrapper'], {
        [_vm.$style['modal-wrapper--enter']]: _vm.isEnter,
        [_vm.$style['modal-wrapper--leave']]: _vm.isLeave,
        [_vm.$style['modal-wrapper--transparent']]: _vm.transparent,
        [_vm.$style['modal-wrapper--size-' + _vm.size]]: _vm.size,
        [_vm.$style['modal-wrapper--top-start']]: _vm.topStart
} ],on:{"click":_vm.closeExact,"scroll":function($event){return _vm.$emit('scroll', $event)}}},[_c('div',{class:_vm.$style['modal-wrapper__window']},[(!_vm.customClose)?_c('div',{class:_vm.$style['modal-wrapper__close'],on:{"click":_vm.overlayClick}},[_c('AppIcon',{attrs:{"icon":"close"}})],1):_vm._e(),_vm._t("default",null,{"close":_vm.close})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }