<template>
    <button :class="['default-button', className, $style['app-button']]" :disabled="disabled" :tabindex="disabled ? '-1' : tabindex" v-bind="$attrs" @click="$emit('click', $event)"><slot/></button>
</template>
<script>
export default {
    name: 'AppButton',
    props: {
        theme: String,
        size: String,
        square: Boolean,
        round: Boolean,
        disabled: Boolean,
        tabindex: [String, Number],
        roundCorner: Boolean
    },
    computed: {
        className() {
            const simple = ['square', 'round', 'disabled', 'roundCorner'];
            const conditional = ['theme', 'size'];
            const obj = {};
            simple.forEach(name => {
                obj[this.$style['app-button--' + name]] = this[name];
            });
            conditional.forEach(name => {
                obj[this.$style['app-button--' + name + '-' + this[name]]] = this[name];
            });
            return obj;
        }
    }
}
</script>
<style lang="scss" module>
@import '../../assets/scss/variables';
.app-button {
    $self: &;
    --height: 48px;
    --bg: var(--color-primary);
    --cl: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg);
    border-radius: 12px;
    height: var(--height);
    padding-left: 42px;
    padding-right: 42px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-family: $font-primary;
    border: none;
    color: var(--cl);
    &:active,
    &:hover {
        --bg: var(--color-primary-dark);
    }
    &--theme-gray {
        --bg: var(--color-border-lighten);
        --cl: var(--color-gray-light);
        &:hover {
            --bg: var(--color-border-light);
        }
        &:active {
            --bg: var(--color-border);
        }
    }
    &--theme-success {
        --bg: var(--color-success);
        box-shadow: 0px 2px 11px var(--color-success-25);
        &:hover {
            --bg: var(--color-success-dark);
        }
    }
    &--theme-success-flat {
        --bg: var(--color-success);
        &:hover {
            --bg: var(--color-success-dark);
        }
    }
    &--theme-greenish {
        --bg: var(--color-white-greenish);
        --cl: var(--color-success);
        &:hover {
            --bg: var(--color-success);
            --cl: var(--color-white);
        }
    }
    &--theme-danger {
        --bg: var(--color-danger);
        &:hover {
            --bg: var(--color-danger-dark);
        }
    }
    &--theme-danger-light {
        --bg: #FF4921;
        &:hover {
            --bg: var(--color-danger-dark);
        }
    }
    &--theme-white {
        --bg: var(--color-white);
        box-shadow: var(--shadow-button);
        --cl: var(--color-gray-light);
        &:hover {
            --bg: var(--color-white-dark);
        }
    }
    &--theme-white-outline {
        border: 1px solid var(--color-border);
        background-color: #fff;
        border-radius: 12px;
        color: var(--color-gray-light);
        &:hover {
            background-color: var(--color-border);
            color: var(--color-dark);
        }
    }
    &--theme-white-new {
        --bg: var(--color-white);
        --cl: var(--color-primary);
        &:hover {
            --bg: var(--color-white-dark);
        }
    }
    &--theme-white-square {
        padding: 12px !important;
        width: 48px;

        svg {
            margin: 0 !important;
        }
        --bg: var(--color-white);
        --cl: var(--color-primary);
        &:hover {
            --bg: var(--color-white-dark);
        }
    }
    &--theme-transparent {
        --cl: var(--color-white-darken);
        background: transparent;
        &:hover {
            --cl: var(--color-black);
        }
    }
    &--theme-blueish-a {
        background: var(--color-white-bluesh);
        color: var(--color-primary);
        &:hover {
            color: var(--color-white);
            background: var(--color-primary);
        }
    }
    &--theme-blueish-b {
        background: var(--color-white-bluesh);
        color: var(--color-primary-dark);
        &:hover {
            color: var(--color-white);
            background: var(--color-primary-dark);
        }
    }
    &--theme-alternate {
        --bg: var(--color-alternate);
        &:active,
        &:hover {
            --bg: var(--color-alternate-dark);
        }
    }
    &--theme-blueish {
        --bg: var(--color-white-bluesh);
        --cl: var(--color-gray-lighten);
        &:active,
        &:hover {
            --bg: var(--color-primary);
            --cl: var(--color-white);
        }
    }
    &--theme-gray-dark {
        background: var(--color-border-dark);
        color: var(--color-white);
        &:hover {
            background: var(--color-gray-light);
        }
    }
    &--theme-client {
        padding-right: 32px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-primary);
        font-family: $font-secondary;
        background: var(--color-white);
        box-shadow: 0px 2px 8px var(--shadow-avatar-01);
        border-radius: 50px;
        height: 40px;
    }
    &--theme-primary-outline-a,
    &--theme-primary-outline {
        border: 1px solid var(--color-primary);
        --cl: var(--color-primary);
        --bg: var(--color-white);
        &:active,
        &:hover {
            --bg: var(--color-primary);
            --cl: var(--color-white);
        }
    }
    &--theme-primary-outline-a {
        --bg: var(--color-white-bluesh);
    }
    &--theme-success-outline {
         border: 1px solid var(--color-success);
         --cl: var(--color-success);
         --bg: var(--color-white-greenish);
         &:active,
         &:hover {
             --bg: var(--color-success);
             --cl: #fff;
         }
     }
    &--theme-primary-dark-outline-a,
    &--theme-primary-dark-outline {
        border: 1px solid var(--color-primary-dark);
        --cl: var(--color-primary-dark);
        --bg: var(--color-white-bluesh);
        &:active,
        &:hover {
            --bg: var(--color-primary-dark);
            --cl: var(--color-white);
        }
    }
    &--theme-primary-dark-outline-a {
        --bg: var(--color-white);
    }
    &--theme-white-outline-narrow,
    &--theme-white-outline {
        border: 2px solid var(--color-border);
        --cl: var(--color-gray-light);
        --bg: var(--color-white);
        &:hover {
            --bg: var(--color-border);
            --cl: var(--color-gray-light);
        }
        &:active {
            --bg: var(--color-border-dark);
            border: 2px solid var(--color-border-dark);
        }
    }
    &--theme-white-outline-narrow {
        border: 1px solid var(--color-border);
        &:active {
            border: 1px solid var(--color-border-dark);
        }
    }
    &--theme-white-flat {
        --bg: var(--color-white);
        --cl: var(--color-gray-light);
        &:active,
        &:hover {
            --bg: var(--color-white-dark);
            --cl: var(--color-gray-light);
        }
    }
    &--theme-primary-dark {
        --bg: var(--color-primary-dark);
        --cl: var(--color-white);
        &:active,
        &:hover {
            --bg: var(--color-primary);
            --cl: var(--color-white);
        }
    }

    &--size-small-a {
        --height: 34px;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        border-radius: 12px;
        font-family: $font-secondary;
    }
    &--size-medium {
        --height: 40px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        border-radius: 4px;
    }
    &--size-medium-extra {
        --height: 44px;
    }
    &--size-small {
        --height: 32px;
        border-radius: 12px;
    }
    &--size-light {
        --height: 36px;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        border-radius: 12px;
        letter-spacing: 0.005em;
    }
    &--size-medium-a {
        --height: 40px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        border-radius: 12px;
    }
    &--size-medium-b {
        --height: 36px;
        padding-left: 17px;
        padding-right: 17px;
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
    }
    &--size-large {
        --height: 48px;
        width: 120px;
        padding-left: 17px;
        padding-right: 17px;
        font-family: $font-secondary;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
    }
    &--size-medium
    &--roundCorner {
        border-radius: 50px;
    }
    &--round,
    &--square {
        width: var(--height);
        padding-left: 0;
        padding-right: 0;
    }
    &--round {
        border-radius: 100%;
    }
    &--disabled {
        opacity: 0.3;
    }
}
</style>
