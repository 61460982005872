<template>
    <article :class="[$style['modal-wrapper'], {
            [$style['modal-wrapper--enter']]: isEnter,
            [$style['modal-wrapper--leave']]: isLeave,
            [$style['modal-wrapper--transparent']]: transparent,
            [$style['modal-wrapper--size-' + size]]: size,
            [$style['modal-wrapper--top-start']]: topStart
    } ]"
             @click="closeExact" @scroll="$emit('scroll', $event)">
        <div :class="$style['modal-wrapper__window']">
            <div v-if="!customClose" :class="$style['modal-wrapper__close']" @click="overlayClick"><AppIcon icon="close"/></div>
            <slot :close="close"/>
        </div>
    </article>
</template>
<script>
import AppIcon from "./AppIcon";
import {disableBodyScroll} from "../../helpers/dom";
import FullScreenModalMixin from "@/mixins/FullScreenModalMixin";

export default {
    name: 'ModalWrapper',
    components: {AppIcon},
    mixins: [FullScreenModalMixin],
    props: {
        transparent: Boolean,
        size: String,
        customClose: Boolean,
        topStart: Boolean
    },
    data() {
        return {
            isEnter: true,
            isLeave: false
        }
    },
    mounted() {
        window.addEventListener('keydown', this.closeOnKeyboard);
        disableBodyScroll(true);
        setTimeout(() => {
            this.isEnter = false;
        }, 250);
    },
    beforeDestroy() {
        disableBodyScroll(false);
        window.removeEventListener('keydown', this.closeOnKeyboard);
    },
    methods: {
        closeOnKeyboard(e) {
            if(e.code === 'Escape') {
                this.$emit('overlay-click');
                this.close();
            }
        },
        closeExact(e) {
            if(e.target.classList.contains(this.$style['modal-wrapper'])) {
                this.close();
            }
        },
        overlayClick() {
            this.$emit('overlay-click');
            this.close();
        },
        close() {
            if (this.modalWindowFullscreen) {
                this.modalWindowFullscreenToggle();
            }

            this.isLeave = true;
            setTimeout(() => {
                this.isLeave = false;
                disableBodyScroll(false);
                this.$emit('close-modal');
                const handler = this.$parent.$listeners['close-modal'];
                if(handler) handler();
            }, 250);
        }
    }
}
</script>
<style lang="scss" module>
.modal-wrapper {
    $self: &;
    transition: background var(--modal-animation-duration, .25s) var(--modal-animation-delay, 0s);
    z-index: 70;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: var(--modal-offset-top, 149px) var(--modal-offset-sides, 60px) var(--modal-offset-bottom, 149px);
    background: var(--modal-overlay, rgba(0, 0, 0, 0.3));
    &__window {
        width: var(--modal-window-width, 1584px);
        max-width: var(--modal-window-max-width, 95%);
        margin: var(--window-margin, auto);
        padding: var(--modal-window-padding-top, 52px) var(--modal-window-padding-sides, 42px) var(--modal-window-padding-bottom, 42px);
        height: var(--modal-window-height, auto);
        background: var(--color-white);
        border-radius: var(--modal-window-radius, 12px);
        position: relative;
    }
    &__close {
        cursor: pointer;
        user-select: none;
        padding: 16px;
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        box-sizing: content-box;
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &--enter {
        animation: modalAnimation .25s 1 forwards;
        #{$self}__window {
            animation: modalWindowAnimation .25s 1 forwards;
        }
    }
    &--leave {
        animation: modalAnimation .25s 1 reverse forwards;
        #{$self}__window {
            animation: modalWindowAnimation .25s 1 reverse forwards;
        }
    }
    &--transparent {
        #{$self}__window {
            width: auto;
            padding: 0;
            background-color: transparent;
        }
    }
    &--size-medium {
        #{$self}__window {
            width: 624px;
        }
    }
    &--top-start {
        #{$self}__window {
            margin-top: 0;
        }
    }
}
@keyframes modalWindowAnimation {
    from {
        transform: translateY(50px);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes modalAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
