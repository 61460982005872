<template>
  <PageLoader v-if="is_loading || !application" />
  <div
    v-else
    class="application-page"
  >
    <admin-card-accordion
      class="mb-32"
      icon="user"
      theme="client"
      title="open-apps-form.clientInfo"
    >
      <template #actions="{ openAccordion }">
        <AppButton
          theme="gray"
          @click="cancel"
        >
          {{ $t("common.back") }}
        </AppButton>
        <AppButton
          :theme="isDirty ? 'primary' : 'success-flat'"
          @click="openAccordion(); saveCustomer"
        >
          {{ create_client_loading ? $t("common.pleaseWaitWithDots")
            : isDirty ? $t("common.update") : $t("common.updated") }}
        </AppButton>
      </template>
      <!-- div не трогать -->
      <div>
        <ClientForm
          v-model="application.customer"
          :key="application.customer.id"
          type="empty"
          incoming-phone=""
          client-bank-editable
          ref="client-form"
          :selected-phone="selectedPhone"
          @input="checkDirty"
          @select-phone="selectedPhone = $event"
          @submit="saveCustomer"
        />
      </div>
    </admin-card-accordion>
    <HistoryApplication
      :application="application"
      :call-data="emulatedCallData"
      :messages="applicationMessages"
      @back-list="cancel"
      @open:modal="openModal($event)"
    />
    <div>
      <div class="mb-8-a" />
      <AdminCard
        body-empty
        icon="user"
        theme="client"
        title="open-apps-form.allRatingsForTreatment"
        #default="{classNames}"
      >
        <AppTable
          v-if="application.ratings.length"
          class="mb-3-a"
          :class="classNames.large"
          :head="ratingsHead"
          :data="application.ratings"
          theme="problem"
        >
          <template #cell.idx="{rowIndex}">
            {{ rowIndex + 1 }}
          </template>
          <template #cell.grade="{item}">
            <div class="d-flex">
              <StarsRating
                class="mr-1-a"
                :value="item.grade"
              /> {{ item.grade.toFixed(1) }}
            </div>
          </template>
          <template #cell.date="{item}">
            {{ formatDateMoment(item.date) }}
          </template>
        </AppTable>
        <div
          v-else
          class="empty-title empty-title--theme-inpage"
        >
          {{ $t("open-apps-form.noRatingsYet") }}
        </div>
        <!-- Не удалять -->
        <div />
      </AdminCard>
    </div>

    <CreditProductModal
      v-if="modals.creditProduct"
      :calculation-type="calculationType"
      :product-info="productInfo"
      :product-table="calculationTable"
      :call-data="emulatedCallData"
      :slide="slide"
      full-screen-fill
      @cancel:form="closeModal"
      @close-modal="closeModal"
      @submit:credit-product="submitCreditProduct"
      @submit:deposit-product="submitDepositProduct"
      @reset:step="slide = 'default'"
    />
  </div>
</template>
<script>
import {get, post, put} from "../../helpers/api";
import SelectedProblem from "../../components/selectedProblem";
import AppIcon from "../../components/partials/AppIcon";
import ChatWindowBody from "../../components/partials/chat/ChatWindowBody";
import PageLoader from "../../components/partials/PageLoader";
import ClientForm from "../../components/ClientForm";
import AdminCard from "../../components/partials/AdminCard";
import AppButton from "../../components/partials/AppButton";
import {parseMessageFromHistory} from "../../helpers/chats";
import HistoryApplication from "../../components/partials/HistoryApplication";
import AppTable from "../../components/partials/AppTable";
import StarsRating from "../../components/partials/StarsRating";
import FilterDate from "../../mixins/FilterDate";
import {getModifiedUserData} from "../../helpers/other";
import LangController from "../../services/LangController";
import {CallRoom} from "../../services/chat/ChatModel";
import CreditProductModal from "@/components/modals/CreditProductModal.vue";
import FullScreenModalMixin from "@/mixins/FullScreenModalMixin";
import AdminCardAccordion from "@/components/AdminCardAccordion.vue";

export default {
    name: "ApplicationForm",
    components: {
        AdminCardAccordion,
        CreditProductModal,
        StarsRating,
        AppTable,
        HistoryApplication,
        AppButton, AdminCard, ClientForm, PageLoader, ChatWindowBody, AppIcon, SelectedProblem},
    mixins: [FilterDate, FullScreenModalMixin],
    data() {
        return {
            application: null,
            applicationMessages: [],
            create_client_loading: false,
            selectedPhone: "",
            is_loading: false,
            isDirty: false,
            ratingsHead: [[
                {
                    name: "common.table.number",
                    key: "idx",
                    width: "160px"
                },
                {
                    name: "common.table.date",
                    key: "date",
                },
                {
                    name: "common.table.operatorName",
                    key: "operator",
                },
                {
                    name: "common.table.receivedScore",
                    key: "grade"
                },
            ]],
            modals: {
                creditProduct: false
            },
            productInfo: {},
            calculationTable: {},
            calculationType: "",
            slide: "default"
        };
    },
    created() {
        this.loadApplication();
    },
    computed: {
        emulatedCallData() {
            if(!this.application) return null;

            return {
                ...new CallRoom({}),
                customers: [this.application.customer],
                selectedCustomer: this.application.customer.id,
                customersProblems: {
                    [this.application.customer.id]: [this.application]
                },
                isNewProblem: false,
                language_id: this.application.language_id
            };
        }
    },
    methods: {
        openModal(id) {
            get(`/products/${id}`).then((res) => {
                if (res.data.success) {
                    this.productInfo = res.data.data;

                    this.modals.creditProduct = true;

                }
            })
                .catch((_,) => {
                    this.$store.commit("notifications/error", "errors.somethingWentWrong");
                });
        },
        submitCreditProduct(form) {
            post("/calculator/credit", form)
                .then((res) => {
                    this.calculationType = "credit";
                    this.calculationTable = res.data.data;
                    this.slide = "results";
                })
                .catch(() => {
                    this.$store.commit("notifications/error", "errors.somethingWentWrong");
                });
        },
        submitDepositProduct(form) {
            post("/calculator/deposit", form)
                .then((res) => {
                    this.calculationType = "deposit";
                    this.calculationTable = res.data.data;
                    this.slide = "results";
                })
                .catch(() => {
                    this.$store.commit("notifications/error", "errors.somethingWentWrong");
                });
        },
        closeModal( ) {
            if (this.modalWindowFullscreen) {
                this.modalWindowFullscreenToggle();
            }

            this.slide = "default";
            this.modals.creditProduct = false;
        },
        checkDirty() {
            this.isDirty = true;
        },
        loadApplication() {
            this.is_loading = true;
            let app_id = this.$route.params.id;
            if (!app_id) this.$router.push("/pages/404");

            get("/applications/" + app_id)
                .then(r => {
                    if (r.data.success) {
                        this.application = {
                            ...r.data.data,
                            customer: getModifiedUserData(r.data.data.customer, "")
                        };
                        this.selectedPhone = r.data.data.phone?.phone || "";
                        this.loadApplicationMessages();
                    }
                }).catch(e => {
                this.is_loading = false;
            });
        },
        loadApplicationMessages() {
            get(`/chat-history/application?customerId=${this.application.customer.id}&applicationId=${this.$route.params.id}`)
                .then(response => {
                    this.applicationMessages = response.data.data.message.map(message => parseMessageFromHistory(message, this.application.customer.username));
                })
                .finally(() => {
                    this.is_loading = false;
                });
        },
        cancel() {
            this.$router.push({
                path: "/open-apps",
                query: this.$route.query
            });
        },
        saveCustomer() {
            let url = `/customers/${this.application.customer.id}`;
            let method = put;

            const formData = {...this.application.customer};

            delete formData.updated_at;
            delete formData.bank_client_id;

            this.create_client_loading = true;

            method(url, formData)
                .then(response => {
                    if (response.data.success) {
                        this.isDirty = false;
                    } else {
                        throw new Error();
                    }
                })
                .catch(e => this.create_client_loading = false)
                .finally(() => this.create_client_loading = false);
        },
    },
};
</script>
