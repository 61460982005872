export const translationExists = {
    methods: {
        getTranslationOrKey(key) {
            if (key === null || key === undefined) {
                return '';
            }

            const { text = key, keys = {} } = key; // If the key comes without parameters, then we substitute it in text
            if (this.$i18n.te(text)) {
                return this.$i18n.t(text, keys);
            }

            return text;
        }
    }
}
